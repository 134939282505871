import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from 'moment';
import VueScroller from 'vue-scroller';
import './assets/css/weui.min.css';
import './assets/iconfont/iconfont.css';
require ('./assets/iconfont/iconfont.js');

Vue.prototype.$moment = moment;
Vue.use(VueScroller);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title;
  }
});
