import axios from 'axios';
import store from "../store";
import wxmp from "@/utils/wxmp";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000,
    headers: {'X-Requested-With': 'XMLHttpRequest'}
});

function request(config) {
    instance({
        method: config.method,
        url: config.url,
        data: config.data
    }).then(res => {
        if (res && config.callback) {
            config.callback(res);
        }
    }).catch(err => {
        alert('http err: ' + err.message);
    });
}

function get(url, params, callback) {
    instance({
        method: 'get',
        url: url,
        params: params
    }).then(res => {
        if (res && callback) {
            callback(res);
        }
    }).catch(err => {
        alert('get err: ' + err.message);
    });
}

function post(url, data, callback) {
    instance({
        method: 'post',
        url: url,
        data: data
    }).then(res => {
        if (res && callback) {
            callback(res);
        }
    }).catch(err => {
        alert('post err: ' + err.message);
    });
}

instance.interceptors.request.use(config => {
    let token = store.state.token;
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    let res = response.data;
    if (res.code == 200) {
        return res;
    } else if (res.code == 401) {
        store.commit('delToken');
        wxmp.authorize();
    } else {
        return Promise.reject({
            status: res.code,
            message: res.message
        });
    }
}, error => {
    return Promise.reject(error);
});

export default { instance, request, get, post }; // 导出自定义instance request get post方法